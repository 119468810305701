.hockey-data-component-livegames {
  .-hd-los-live-games {
    margin: -6px;

    h3 {
      display: none;
    }
  }

  .-hd-los-game-live-box-wrapper {
    margin: 6px;
    width: calc(100% - 12px);

    @include media-breakpoint-up(lg) {
      margin: 6px;
      width: calc(50% - 12px);
    }
  }
}
