// Font imports
@font-face {
  font-family: 'Outfit';
  font-display: swap;
  src: url('/assets/fonts/Outfit.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('/assets/fonts/Inter.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('/assets/fonts/Inter-Italic.woff2') format('woff2');
  font-weight: 100 900;
  font-style: italic;
}

