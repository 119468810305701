.-hd-los-game-full-report {
  .-hd-los-game-full-report-team-logo img {
    max-height: 80px !important;
    margin-top: 10px;
  }

  .-hd-button-group {
    border-radius: 4px;
    overflow: hidden;
  }

  .-hd-los-game-full-report-game-info,
  .-hd-los-game-full-report-game-facts {
    padding: $space-m !important;
  }

  .-hd-los-game-full-report-game-fact-label,
  .-hd-los-game-full-report-period-stats-score > div:first-child,
  .-hd-los-game-full-report-period-stats-additional > div:first-child {
    font-weight: 700;
  }

  .-hd-util-intellitable-data {
    @extend .border-radius-small;
  }
}
