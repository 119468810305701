.paragraph {
  margin-bottom: $space-m;
}

.text-paragraph,
.liveblog-paragraph p {
  line-height: 1.575rem;
}

.text-paragraph {

  ul {
    list-style-type: square;
    padding-left: $space-s;

    &.read-more-list {
      list-style: none;

      li::before {
        content: "";
        border-color: transparent var(--color);
        border-style: solid;
        border-width: 0.35em 0 0.35em 0.45em;
        display: block;
        height: 0;
        width: 0;
        left: -1em;
        top: 0.9em;
        position: relative;
      }
    }

  }

  h2,
  h3 {
    font-family: var(--heading-font);
    color: var(--title-color);
    line-height: 120%;
  }

  h2 {
    font-size: 1.375rem;
    line-height: 1.725rem;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  a {
    text-decoration: underline;
  }

}

.cumulus-video-paragraph {
  position: relative;

  .oasis-player {
    @extend .border-radius;
  }
}

.video-paragraph {
  @extend .border-radius;

  display: block;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.image-paragraph {
  position: relative;
  margin-bottom: $space-l;

  img {
    display: block;
    width: 100%;
  }
}

.twitter-paragraph {
  .twitter-tweet {
    width: 100% !important;
    overflow-wrap: break-word;
  }
}

.gallery-paragraph {
  position: relative;
  overflow: hidden;

  .slick-arrow {
    display: none !important;
  }

  .btn {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 100;
    box-shadow: none;
    border: none;

    &:before {
      @extend .border-radius-small;

      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 48px;
      width: 48px;
      left: 12px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:focus {
      box-shadow: none;
    }

    .icon {
      position: relative;
      z-index: 200;
      width: 20px;
      height: 20px;
    }
  }

  .button-left {
    padding-right: $space-xl;
    left: 0;
  }

  .button-right {
    padding-left: $space-xl;
    right: 0;

    &:before {
      left: auto;
      right: 12px;
    }
  }

  .icon-arrow-left {
    left: 18px;
    background-image: url(/assets/icons/arrow-left-white.svg);
  }
  .icon-arrow-right {
    left: auto;
    right: 18px;
    background-image: url(/assets/icons/arrow-right-white.svg);
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    img {
      width: 100%;
    }
  }

  .gallery-image {
    position: relative;
    margin-bottom: 0;
  }
}

.image-paragraph,
.gallery-image {
  img {
    @extend .border-radius;
  }
}

.quote-paragraph {
  position: relative;

  & > div {
    @extend .font-size-large;
    padding-left: 40px;
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  a {
    line-break: anywhere;
  }

  footer {
    font-style: italic;
  }
}

.liveblog-paragraph {
  h2 {
    margin-top: $space-xs;
    margin-bottom: $space-m;
  }

  .live-tag .text-highlight {
    margin-right: $space-s;
  }

  .liveblog-post {
    padding: $space-xs 0 $space-xs $space-m;
    animation: 0.25s ease-in-out 0s 1 fadeInOpacity;
    border-left: 2px solid var(--lightest-gray-flat);
    position: relative;

    &:first-child {
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 40px;
        position: absolute;
        left: -2px;
        top: 0;
        border-left: 2px solid var(--white);
      }

      .breadcrumb:after {
        display: block;
      }
    }

    .breadcrumb {
      font-family: Arial, Helvetica, sans-serif;
      display: block;
      margin-top: $space-s;
      margin-bottom: 4px;
      float: none;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        left: -6px;
        top: 24px;
        background-color: $red;
        border-radius: 5px;
        z-index: 500;
      }

      &:after {
        content: '';
        display: none;
        width: 23px;
        height: 23px;
        position: absolute;
        left: -12px;
        top: 18px;
        background-color: var(--white);
        border-radius: 23px;
        border: 2px solid var(--lightest-gray-flat);
        z-index: 400;
      }

      .text-highlight {
        margin-left: $space-xs;
      }
    }

    h3 {
      position: relative;
    }

    .paragraph {
      margin-left: 0 !important;
    }
  }

  .load-more {
    margin-top: $space-m;
    text-align: center;

    .btn {
      position: relative;
      padding-left: $space-l;
      padding-right: $space-l;
      font-weight: 600;
    }

    .lds-dual-ring {
      display: none;
      position: absolute;
      right: 10px;
      top: 8px;
    }

    &.is-loading {
      .lds-dual-ring {
        display: block;
      }
    }
  }

  .cleverpush-widget-container {
    margin-top: $space-l;
  }
}

.tiktok-paragraph {
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (min-width: 576px) {
    justify-content: flex-start;
  }
}

.qualifio-paragraph {
  height: auto;
  iframe {
    border-width: 0;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
