@mixin arrow-icon-link {
  &:after {
    content: '';
    display: inline-block;
    background-image: url(/assets/icons/arrow-right.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transition: 0.2s transform ease-in-out;
  }

  &:hover {
    text-decoration: none;
    color: var(--title-color);

    &:after {
      transform: translateX(4px);
    }
  }
}

.component-title,
.teaser-carousel .teaser-list-title h3 {
  font-family: var(--heading-font);
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 2rem;
  color: var(--title-color);
  padding-left: 20px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: $space-m;

  &:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 30px;
    top: 0;
    left: 0;
    transform: skewX(-12deg);
    background-color: var(--red-alt);
  }

  a {
    color: var(--title-color);

    @include arrow-icon-link;
  }
}

.section-title,
.row > .component-title {
  //padding-left: $space-s;

  @include media-breakpoint-down(sm) {
    //padding-left: $space-xxs;
  }
}

// Fix SingleTeaser's title overflow for different screen sizes.
@media screen and (min-width: 62rem) and (max-width: 92rem) {
  .single-teaser > .component-title {
    white-space: nowrap;
    margin-bottom: 16px;
    padding-left: 0;

    &:before {
      display: none;
    }

    .teaser-anchor:after {
      margin-left: 5px;
    }

  }
}
