.team-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .team-icon {
    position: relative;
    flex: 0 0 120px;
    margin: $space-m $space-s;

    &:before {
      @extend .box-shadow-small;
      content: '';
      position: relative;
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 10px $space-xs;
      border-radius: 50px;
      background-color: $white;
    }

    .icon-title {
      display: block;
      text-align: center;
      color: var(--color);
      text-decoration: none;
    }

    & > div {
      width: 60px;
      height: 60px;
      top: 20px;
      left: 30px;
      position: absolute;
    }
  }
}
