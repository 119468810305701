.mira-wrapper {
  position: relative;

  .mira-handle {
    cursor: pointer;
  }

  &.logged-in {
    .icon-person,
    .icon-person-dark {
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: $red;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        right: 0;
        top: 0;
      }
    }
  }

  .mira-popup {
    @include tooltip-dropdown;

    top: 40px;

    @media screen and (max-width: 68.75rem) {
      top: unset;
      bottom: 40px;

      &:before {
        top: unset;
        bottom: -5px;
      }
    }

    right: -$space-s;
    list-style-type: none;

    &.visible {
      display: block;
    }

    .user-name {
      font-weight: 600;
      margin-bottom: 6px;
      display: block;
      color: $gray-dark;
    }

    li {
      margin: 0;
    }

    button {
      padding-top: 4px;
    }
  }
}

.header-bright {
  .mira-popup {
    top: 50px;
  }
}
