.-hd-los-player-full-page {
  .-hd-los-player-full-page-player-info {
    margin: $space-s 0;
  }

  .-hd-los-player-full-page-player-portrait {
    margin-bottom: 0;

    img {
      @extend .box-shadow-small;
      @extend .border-radius-small;

      border: none !important;
    }
  }

  .-hd-los-player-full-page-player-name {
    margin-top: 0 !important;
    white-space: normal !important;
  }

  .-hd-los-player-full-page-team-logo {
    margin: 0 0 $space-s !important;
    padding: 0 $space-s 0;
  }

  .-hd-los-player-full-page-headline {
    padding: $space-s 0 !important;
  }

  .-hd-los-player-full-page-container-data {
    @extend .border-radius-small;

    margin: 0;
  }

  .-hd-util-tabs .-hd-util-tabs-content {
    padding: 0 !important;
  }

  .-hd-los-player-full-page-player-facts {
    margin-bottom: $space-m;

    .-hd-los-player-full-page-player-fact-row {
      width: auto;
      display: block;
      margin-left: $space-m;

      .-hd-los-player-full-page-player-fact-label {
        width: 140px;
        font-weight: 600;
        text-align: left;
      }

      .-hd-los-player-full-page-player-fact-value {
        width: auto;
      }
    }
  }
}
