.teaser-slider {
  position: relative;

  .teaser-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: $space-s;

    @include media-breakpoint-down(md) {
      margin-top: $space-s;
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .page-indicator {
      margin-left: $space-xs;
      color: darken($gray-light, 16%);
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }

  .teaser-collection {
    position: relative;

    .slick-slider {
      margin-left: -12px;
      margin-right: -12px;
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-track {
      display: flex;

      // Hack to make 4-slide slick-slider accessible.
      // See: https://tobiasahlin.com/blog/previous-sibling-css-has
      [aria-hidden="true"]:has(~ * + .slick-active) {
        visibility: hidden;
      }
    }

    a:hover {
      text-decoration: none;
    }

    .teaser-list-slide {
      padding: 0 $space-s;
    }

    .teaser {
      width: 100%;
      display: flex;
      margin-bottom: 0;
    }

    .text-section {
      flex: 0 0 auto;
      white-space: initial;

      .teaser-excerpt {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      .teaser-anchor {
        padding: 0 12px;
      }
    }

    @include media-breakpoint-up(lg) {
      .text-section .teaser-excerpt {
        display: inherit;
      }
    }
  }
}

.controlPrev,
.controlNext {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: calc(50% - 1rem);
  transform: translateY(-50%);

  svg {
    fill: var(--title-color);
    width: .625rem;
    height: 1.625rem;
  }

  &:disabled {
    opacity: 50%;
  }
}

.controlPrev {
  left: -1.375rem;
}

.controlNext {
  right: -1.375rem;
}
