.social-button-group {
  margin-bottom: $space-s;

  a {
    display: inline-block;
    margin-right: 12px;
  }

  .icon {
    &.click-animate {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        opacity: 1;
        transform: translateY(0);
        visibility: hidden;
      }

      &.active-animation:before {
        visibility: visible;
        animation: icon-effect 0.5s ease-in forwards;
      }
    }

    &.icon-copy-round:before,
    &.icon-copy:before {
      background-image: url('/assets/icons/copy-link-dark.svg');
    }
  }
}

@keyframes icon-effect {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
