.hockey-data-component.hockey-data-component-divisionpicker {
  & > div {
    overflow: visible !important;
  }

  .-hd-los-division-picker .-hd-los-division-picker-widget-container {
    padding: 0;
  }

  .-hd-util-intellitable .-hd-util-intellitable-data {
    border-radius: 0 0 4px 4px;
  }

  .-hd-los-team-full-page-player-stats-data.-hd-util-intellitable .-hd-util-intellitable-data,
  .-hd-los-team-full-page-games-data.-hd-util-intellitable .-hd-util-intellitable-data {
    @extend .border-radius-small;
  }
}
