.fullbanner-wrapper .container {
  min-height: $space-base + 50;

  @include media-breakpoint-up(md) {
    height: $space-base + 250;
  }
}

.sticky-sky-wrapper {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 100%;

  @include media-breakpoint-down(xs) {
    display: none !important;
  }

  @include media-breakpoint-up(sm) {
    width: 160px;
    right: $space-m;
  }

  @include media-breakpoint-up(xxl) {
    width: 300px;
  }
}

.ad-component-wrapper {
  margin-bottom: $space-s !important;

  &.ad-component-skyscraper {
    position: sticky;
    top: 50px;
  }

  &.somtag-product--mediumRectangle {
    margin-bottom: $space-m !important;
  }

}

.ad-inread-wrapper {
  margin-bottom: $space-s;

  &:empty {
    display: none;
  }
}

.somtag--collapsed {
  display: none;
}
