.article {
  margin-bottom: $space-l;

  .lead {
    //@extend .box-shadow-small;
    //@extend .border-radius;

    margin-bottom: 0;
    position: relative;
    overflow: hidden;

    &.has-video .oasis-player {
      padding-bottom: 56.25%;
      height: 0;
    }

    img {
      width: 100%;
    }
  }

  .article-background {
    //@extend .box-shadow-small;
    //@extend .border-radius;

    margin-top: $space-m;
    @include media-breakpoint-up(xl){
      margin-top: $space-l;
    }
    //padding: $space-m;
    //background-color: $white;

    //@include media-breakpoint-up(md) {
    //  margin-top: $space-m;
    //  padding: $space-m + $space-s $space-l;
    //}
    //
    //@include media-breakpoint-between(md, lg) {
    //  padding: $space-l + $space-s $space-xl;
    //}
    //
    //@include media-breakpoint-up(xxl) {
    //  padding: $space-l + $space-s $space-xl;
    //}
  }

  header {
    position: relative;
    margin-bottom: 2.5rem;
    text-align: center;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .breadcrumb-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $space-m;
      @include media-breakpoint-up(xl){
        margin-bottom: $space-l;
      }
      &__widget {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .75rem;
        position: relative;
        z-index: 1;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform: skew(-12deg);
          z-index: -1;
          background: var(--color-primary);
        }

        .category {
          font-family: var(--heading-font);
          font-size: 1.25rem;
          color: var(--color-primary);
          padding: .5rem 1rem;
          margin-left: -1px;
          position: relative;
          z-index: 1;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: skew(-12deg);
            z-index: -1;
            background: var(--white);
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .breadcrumb-categories {
      margin-right: $space-xs;

      & > a {
        display: inline-block;
      }
    }

    .social-button-group {
      height: 24px;
      margin-bottom: 0;
      display: block;
      float: left;

      a {
        margin-right: $space-xxs;
        margin-bottom: $space-xxs;
      }
    }

    h1 {
      margin-bottom: $space-m;
      font-weight: 600;
      font-size: 2.125rem;

      @include media-breakpoint-down(sm) {
        font-size: 2.125rem;
        line-height: 2rem;
      }
    }
  }

  .breadcrumb {
    color: var(--title-color);
    font-size: 1rem;
    line-height: .85rem;
    font-weight: 500;

    .separator {
      margin: 0 5px;
    }

    a {
      color: $light-gray-flat;
    }
    @media screen and (min-width: 992px) {
      font-size: 1.2rem;
    }
  }

  h1 {
    font-family: var(--heading-font);
    color: var(--title-color);
    line-height: 120%;
  }

  .teaser-text {
    font-weight: 600;
    line-height: 1.575rem;
    color: var(--color);
  }

  .teaser-paragraph {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px;
  }

  header:not(:first-child),
  .content {
    .oasis-player {
      height: 0;
      padding-bottom: 56.25%;
    }
  }

  .summary {
    background-color: var(--background-offset);
    padding: 2.2rem 1rem;
    font-size: 1rem;
    line-height: 1.4rem;
    color: var(--color-summary);

    h5 {
      font-family: var(--heading-font);
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 500;
      margin-bottom: 1.25rem;
      position: relative;
      padding-left: 25px;

      &:before {
        content: " ";
        position: absolute;
        width: 12px;
        height: 20px;
        top: 0;
        left: 0;
        transform: skewX(-12deg);
        background-color: var(--red-alt);
      }
    }

    ul {
      padding-left: 1.6rem;
      margin: 0;
    }
  }

  .article-origin {
    border-top: 1px solid $lightest-gray-flat;
    padding: $space-s 0;
    color: var(--color);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;

    .authors {
      display: block;
    }
  }
}

.video-article {
  .content {
    margin-bottom: 0.75rem;
  }

  .teaser-text {
    margin-bottom: 0;
  }

  & .banner-component {
    margin-top: $space-s;
    margin-bottom: $space-s;

    @include media-breakpoint-up(md) {
      margin-top: $space-m;
      margin-bottom: $space-m;
    }
  }
}

.article .social-button-group,
.video-article .social-button-group {
  a {
    background: var(--white);
    color: var(--color-primary);
    box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
    padding: .25rem .75rem;
    font-size: 1rem;
    line-height: 1.562rem;
    margin-right: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    svg.social-icon {
      width: 1.125rem;
      height: 1.125rem;
      fill: var(--color-primary);
      margin-right: 5px;
    }
  }
}

.authors {
  svg.social-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--red);
  }
}

.article-pretitle {
  font-size: 20px;
  margin-bottom: .625rem;
}
