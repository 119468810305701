.footer {
  font-family: var(--footer-font);
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: .875rem;
  line-height: 1.3125rem;
  color: var(--white);
  position: relative;
  width: 100%;
  background-color: var(--background-footer);

  .logo {
    width: 7rem;
    height: 3.5rem;
    fill: var(--white);
    margin-bottom: 20px;
  }

  .funding {
    font-size: .6875rem;
    line-height: 1.3125rem;
    margin-bottom: 30px;
  }

  .links {
    font-size: .6875rem;
    display: block;
    margin-bottom: 30px;
    a {
      color: var(--white);
      text-decoration: underline;
    }
  }

  .copyright {
    display: block;
    margin-bottom: 30px;
  }

  .social-button-group {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--white);
    }
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 60px 0 20px 0;
  }

  .navigation {
    & > ul {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      & > li {
        width: 100%;
        margin: 0;

        h4 > a {
          color: $gray-mid;
        }

        li {
          margin: 0 0 12px 0;
          display: block;

          a {
            color: var(--white);
          }
        }
      }
    }
  }

  &.menu-expanded {
    .navigation {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .expand-menu {
      button {
        svg {
          transform: none;
        }
      }
    }
  }

  & > .expand-menu {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    button {
      border: 0;
      background-color: transparent;
      svg {
        width: 3rem;
        height: 1.5rem;
        transform: rotate(180deg);
      }
    }
    text-align: center;
    padding: 30px 0;
  }

}
