.weather-text {
  position: relative;

  @include media-breakpoint-up(lg) {
    height: calc(100% - 24px);
    max-height: 570px;
  }

  overflow: hidden;
  font-size: .9rem;
  background-color: #ffffff;
  border-radius: 4px;
  @extend .box-shadow-small;

  > .wrapper {
    background-color: #ffffff;
    border-radius: 4px;
    padding: $space-m;
  }

  &.expanded {
    overflow: visible;

    > .wrapper {
      position: absolute;
      z-index: 1201;
    }
  }
}
