.tab-container {
  @include submenu-container;
}

.category-tabs {
  @include submenu;

  min-width: 540px;
  width: 100%;
}

.category-tabs-item {
  @include submenu-item;

  @media (max-width: 992px) {
    // Due to iOS Safari issue with justify-content.
    margin-left: 16px;
    margin-right: 16px;
  }
}

.masonry-container {
  padding: $space-m 0 0 $space-s + 4px;

  .masonry-bottom {
    overflow: auto;

    .lds-dual-ring {
      margin: $space-m auto;
      display: block;
    }

    .lds-dual-ring:after {
      border: 2px solid $gray-light;
      border-color: $gray-light transparent $gray-light transparent;
    }
  }
}

.news-feed-container {
  .section-title {
    padding: 0 4px;

    &:after {
      content: '';
      display: block;
      margin: 2px 0 18px;
      border-bottom: 1px solid $lighter-gray-flat;
    }
  }
}

.news-feed-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -48px; /* gutter size offset */
  width: auto;
}
.news-feed-grid_column {
  padding-left: 48px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.news-feed-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.news-feed-item {
  @extend .border-radius;
  @extend .box-shadow-small;

  background-color: var(--background-offset);
  margin-bottom: $space-l;
  padding: 16px;

  .teaser.teaser-xsmall {
    height: auto;
    margin-bottom: 0;

    .teaser-image {
      flex: 0 0 100px;
      height: auto;
      margin-left: -30px;
      border-radius: 0;

      @include media-breakpoint-up(lg) {
        flex: 0 0 160px;
      }
    }

    .text-section {
      padding: 0;
      margin-top: 4px;
      margin-left: 16px;

      @include media-breakpoint-up(lg) {
        margin-top: 10px;
      }
    }

    .meta-data {
      left: auto;
      bottom: auto;
      position: relative;
      margin-bottom: $space-xxs;
    }

    .teaser-title {
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }

  ul {
    padding-left: $space-m;
    margin-top: $space-s;
    margin-bottom: $space-s;
    list-style-type: square;

    li {
      @extend .font-size-small;
      margin-bottom: $space-xs;
    }
  }

  .teaser-anchor-bottom {
    @include arrow-icon-link;
    color: $gray-mid;
    font-size: 0.85rem;
    line-height: 1rem;
    font-weight: 600;
    margin-left: $space-s;

    &:after {
      margin-bottom: -3px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
