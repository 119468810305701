.navigation {
  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 10px;
    }
  }
}
