.hockey-data-component.hockey-data-component-team-fullpage {
  .-hd-los-team-full-page-team-logo {
    margin: 10px 0 0;
  }

  .-hd-util-tabs-pane .-hd-util-tabs .-hd-util-tabs-button {
    line-height: 32px;
  }

  .-hd-util-tabs-pane .-hd-los-team-full-page-roster .-hd-util-tabs-buttons {
    margin: 0;
  }

  .-hd-util-intellitable-data {
    @extend .border-radius-small;
  }

  .-hd-los-team-full-page-roster .-hd-util-tabs-content {
    padding: 0;
  }
}

.-hd-los-team-full-page-roster-player-container {
  @extend .box-shadow-small;
  @extend .border-radius-small;

  overflow: hidden;
}

.-hd-los-team-full-page-headline {
  padding: 10px 0 !important;
}

.-hd-los-team-full-page-roster-player-details {
  background-color: rgba(0, 0, 0, 0.5);
  color: $white;
}

.hockey-data-component.hockey-data-component-divisionpicker {
  .-hd-los-team-full-page-team-logo {
    margin: 10px 0 0;
  }

  .-hd-util-tabs .-hd-util-tabs-content .-hd-los-team-full-page-roster .-hd-util-tabs-content {
    padding: 0 !important;

    .-hd-util-intellitable-data {
      @extend .border-radius-small;
    }
  }

  .-hd-los-team-full-page-player-stats-data.-hd-util-intellitable .-hd-util-intellitable-data,
  .-hd-los-team-full-page-games-data.-hd-util-intellitable .-hd-util-intellitable-data {
    @extend .border-radius-small;
  }

  .-hd-los-team-full-page-roster.-hd-util-tabs .-hd-util-tabs-buttons,
  .-hd-los-team-full-page-roster.-hd-util-tabs .-hd-util-tabs-pane .-hd-util-tabs-buttons {
    margin: 0;
  }
}
