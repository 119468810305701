// custom variables
$custom-control-purple: #56147c;
$input-btn-focus-color: rgba($custom-control-purple, 0.25);
$custom-control-indicator-focus-box-shadow: 0 0 0 2px $input-btn-focus-color;

$custom-control-indicator-checked-color: $custom-control-purple;
$custom-control-indicator-checked-border-color: $custom-control-purple;
$custom-control-indicator-checked-bg: $custom-control-purple;
$custom-control-indicator-checked-box-shadow: $custom-control-purple;
$custom-control-indicator-focus-border-color: lighten($custom-control-purple, 25%);

$custom-control-indicator-active-color: $custom-control-purple;
$custom-control-indicator-active-bg: lighten($custom-control-purple, 50%);
$custom-control-indicator-active-border-color: lighten($custom-control-purple, 25%);
$custom-control-indicator-active-box-shadow: $custom-control-indicator-focus-box-shadow;

$grid-gutter-width: 24px;

$grid-breakpoints: (
  xs: 0,
  smobile: 374px,
  sm: 576px,
  md: 780px,
  lg: 992px,
  xl: 1080px,
  xxl: 1281px,
);
