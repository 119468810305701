.-hd-los-game-live-box {
  @extend .box-shadow-small;
  @extend .border-radius;

  background-color: $white;
  padding: $space-s;

  .-hd-los-game-live-box-period-stats {
    padding: $space-s 0;
    margin-bottom: $space-s;
  }

  .-hd-los-game-live-box-period-stats-score,
  .-hd-los-game-live-box-period-stats-additional {
    div:first-child {
      font-weight: 700;
    }
  }

  .-hd-los-game-live-box-tabs .-hd-util-tabs-content {
    border: 1px solid $lightest-gray-flat;
    border-radius: 4px;
    bottom: 28px !important;
  }

  .-hd-los-game-live-box-tabs {
    border-left: none !important;
    border-right: none !important;
  }

  .-hd-util-tabs,
  .-hd-util-tabs .-hd-util-tabs-pane {
    .-hd-util-tabs-button {
      border-radius: 4px 4px 0 0;
      color: $gray-dark;
      font-weight: 500;
      line-height: 20px !important;
      border: none !important;
      height: 34px;

      &:hover {
        text-decoration: underline;
      }

      &.-hd-util-tabs-button-active {
        font-weight: 700;
      }
    }

    .-hd-util-tabs-content {
      padding: 0 !important;
    }
  }

  .-hd-los-game-live-box-ticker-action {
    @extend .clearfix;

    padding: $space-s $space-xxs;
    margin-bottom: 0 !important;

    &:nth-child(odd) {
      background-color: $lightest-gray-flat;
    }
    &:nth-child(even) {
      background-color: $white;
    }

    .-hd-los-game-live-box-ticker-action-team-logo {
      margin-top: 0;
    }

    .-hd-los-game-live-box-ticker-action-headline {
      padding-top: 0;
    }
  }

  .-hd-los-game-live-box-team-stats-row td:first-child {
    padding-left: $space-s !important;
  }
}

.hockey-data-component.hockey-data-component-game-livebox
  .-hd-los-game-live-box
  .-hd-util-tabs
  .-hd-util-tabs-content {
  padding: 0 !important;
}

.-hd-los-schedule
  .-hd-util-intellitable
  .-hd-util-intellitable-data
  .-hd-label-LIVE:nth-child(odd)
  .-hd-los-schedule-score,
.-hd-los-schedule
  .-hd-util-intellitable
  .-hd-util-intellitable-data
  .-hd-label-LIVE:nth-child(odd)
  .-hd-los-schedule-score-divider,
.-hd-los-game-live-box
  .-hd-los-game-live-box-period-bar
  .-hd-los-game-live-box-period-bar-active
  .-hd-los-game-live-box-period-bar-fill {
  background-color: transparent !important;
}

.-hd-los-schedule
  .-hd-util-intellitable
  .-hd-util-intellitable-data
  .-hd-label-LIVE:nth-child(even)
  .-hd-los-schedule-score,
.-hd-los-schedule
  .-hd-util-intellitable
  .-hd-util-intellitable-data
  .-hd-label-LIVE:nth-child(even)
  .-hd-los-schedule-score-divider {
  background-color: $lightest-gray-flat !important;
}
