$game-slider-height-mobile: 108px;
$game-slider-height: 160px;
$game-slider-border-spacing: 12px;

.hockey-data-component-gameslider {
  position: relative;
  min-height: 160px;

  @include media-breakpoint-down(sm) {
    min-height: 108px;
  }

  & > div {
    @extend .box-shadow-small;
    @extend .border-radius;

    background-color: var(--background);
  }

  .-hd-los-game-slider {
    height: $game-slider-height;

    .-hd-util-slider {
      background-color: transparent;
      position: relative;
      height: $game-slider-height;

      .-hd-util-slider-button {
        @extend .icon;

        z-index: 1200;
        position: absolute;
        left: 0;
        top: 0;
        padding: 12px;
        background-size: initial;
        height: 100%;

        span {
          &:after {
            @extend .icon;

            content: '';
            position: absolute;
            left: 6px;
            top: 50%;
            margin-top: -6px;
            width: 12px;
            height: 12px;
          }
        }

        &.-hd-util-slider-button-disabled {
          opacity: 0.5;
        }
      }

      .-hd-util-slider-button-prev {
        span {
          &:after {
            @extend .icon-arrow-left;
          }
        }
      }

      .-hd-util-slider-button-next {
        left: auto;
        right: 0;

        span {
          &:after {
            left: auto;
            right: 6px;
            @extend .icon-arrow-right;
          }
        }
      }

      .-hd-util-slider-content {
        left: 24px;
        right: 24px;

        .-hd-util-slider-group {
          height: $game-slider-height;
        }

        table {
          border-spacing: 0;
          border-collapse: collapse;
          left: -$game-slider-border-spacing;
        }

        td {
          height: 100%;
          border-right: 1px solid $lighter-gray-flat;

          &:first-child {
            padding-left: 0 !important;
            border-left: 1px solid $lighter-gray-flat;
          }

          &:last-child {
            padding-right: 0 !important;
          }

          .-hd-los-game-slider-score-info {
            display: none;
          }

          .-hd-los-game-slider-info {
            padding: 0 4px;
          }

          .-hd-los-game-slider-team-name {
            margin-top: 2px;
          }

          @include media-breakpoint-down(sm) {
            .-hd-util-slider-inline-image {
              margin-top: 0;
            }

            .-hd-los-game-slider-team-logo-home {
              margin-left: 4px;
            }

            .-hd-los-game-slider-team-logo-away {
              margin-right: 4px;
            }

            .-hd-los-game-slider-score {
              clear: both;
              padding-top: 10px;
            }
          }
        }

        .-hd-los-game-slider-game.-hd-label-LIVE {
          background-color: transparent;
        }
      }

      @include media-breakpoint-up(md) {
        .-hd-util-slider-button {
          width: 36px;

          span {
            &:after {
              left: 9px;
              top: 50%;
              margin-top: -9px;
              width: 18px;
              height: 18px;
            }
          }
        }

        .-hd-util-slider-content {
          left: 36px;
          right: 36px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      height: $game-slider-height-mobile;

      .-hd-util-slider {
        height: $game-slider-height-mobile;

        .-hd-util-slider-content {
          .-hd-util-slider-group {
            height: $game-slider-height-mobile;
          }
        }
      }
    }
  }
}

.region:not(.region-index-0) > .hockey-data-component-gameslider,
.region-index-0 > .hockey-data-component:not(:first-child) {
  .-hd-los-game-slider {
    .-hd-util-slider {
      .-hd-util-slider-button {
        background-color: rgba(0, 0, 0, 0.5);

        span {
          &:after {
            left: 12px;
          }
        }
      }

      .-hd-util-slider-button-next {
        span {
          &:after {
            right: 12px;
          }
        }
      }
    }
  }
}
