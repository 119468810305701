// make imports globally available
@import '~slick-carousel/slick/slick.scss';

// @import './normalize';
@import './bootstrap';
@import './variables';
@import './fonts';
@import './common';

// Pages

@import '../pages/index.scss';

// Components

// contains exports
@import '../components/Submenu/Submenu.scss';

@import '../components/Ad/Ad.scss';
@import '../components/Article/Article.scss';
@import '../components/Branding/BrandedTitle.scss';
@import '../components/Footer/Footer.scss';
@import '../components/SectionComponent/styles.scss';
@import '../components/OasisPlayer/OasisPlayer.scss';
@import '../components/Navigation/Navigation.scss';
@import '../components/Paragraph/Paragraph.scss';
@import '../components/SocialButtons/SocialButtons.scss';
@import '../components/Message/Message.scss';
@import '../components/WeatherImage/WeatherImage.scss';
@import '../components/WeatherText/WeatherText.scss';
@import '../components/NewsFeed/NewsFeed.scss';
@import '../components/Teaser/Teaser.scss';
@import '../components/TeaserSlider/TeaserSlider.scss';
@import '../components/TeaserCarousel/TeaserCarousel.scss';
@import '../components/Error/Error.scss';
@import '../components/Hockey/HockeyDataComponent.scss';
@import '../components/auth/mira.scss';
@import '../components/Cleverpush/Cleverpush.scss';
@import '../components/StorySlider/StorySlider.scss';

/**********************
*    NEW VARIABLES    *
**********************/

:root {
  --purple-bright: #56147c;
  --purple: #3c0a64;
  --purple-dark: #2a064c;

  --color-primary-bright: #2640bf;
  --color-primary: #1a2b80;
  --color-primary-dark: #131F5A;
  --color-primary-darker: #011F4D;
  --color-primary-darkest: #001534;

  --white: #ffffff;
  --black: #333333;
  --red: #e50046;
  --red-alt: #e60449;
  --green: #37ca21;
  --light-blue: #5770ac;
  --light-blue-gray: #eaebf0;
  --light-blue-gray-alt: #929AAE;
  --light-gray-flat: #b2b2b2;
  --lighter-gray-flat: #dedede;
  --lightest-gray-flat: #f5f5f5;

  --gray-darkest: #1d1f27;
  --gray-darker: #2b2e3b;
  --gray-dark: #414559;
  --gray-mid: #5e616e;
  --gray-light: #8f93a3;
  --gray-lighter: #c8c9d0;
  --gray-blue: #585d6b;

  --body-font: 'Inter', sans-serif;
  --heading-font: 'Outfit', sans-serif;
  --footer-font: 'Outfit', sans-serif;

  &[data-theme=light] {
    --background: var(--white);
    --background-offset: var(--light-blue-gray);
    --background-teaser: var(--color-primary);
    --background-footer: var(--color-primary-dark);
    --color: var(--gray-blue);
    --title-color: var(--color-primary);
    --meta-color: var(--gray-blue);
    --color-summary: var(--color-primary-darker);
    --color-paragraph-anchor: var(--gray-dark);
    --background-infobox: var(--color-primary);
  }
  @media (prefers-color-scheme: light) {
    --background: var(--white);
    --background-offset: var(--light-blue-gray);
    --background-teaser: var(--color-primary);
    --background-footer: var(--color-primary-dark);
    --color: var(--gray-blue);
    --title-color: var(--color-primary);
    --meta-color: var(--gray-blue);
    --color-summary: var(--color-primary-darker);
    --color-paragraph-anchor: var(--gray-dark);
    --background-infobox: var(--color-primary);
  }

  &[data-theme=dark] {
    --background: var(--color-primary-darker);
    --background-offset: var(--color-primary-darkest);
    --background-teaser: var(--color-primary);
    --background-footer: var(--color-primary-darkest);
    --color: var(--light-blue-gray);
    --title-color: var(--white);
    --meta-color: var(--light-blue-gray-alt);
    --color-summary: var(--white);
    --color-paragraph-anchor: var(--red);
    --background-infobox: var(--color-primary-darkest);
  }
  @media (prefers-color-scheme: dark) {
    --background: var(--color-primary-darker);
    --background-offset: var(--color-primary-darkest);
    --background-teaser: var(--color-primary);
    --background-footer: var(--color-primary-darkest);
    --color: var(--light-blue-gray);
    --title-color: var(--white);
    --meta-color: var(--light-blue-gray-alt);
    --color-summary: var(--white);
    --color-paragraph-anchor: var(--red);
    --background-infobox: var(--color-primary-darkest);
  }

  &[data-player-padding=true] {
    padding-top: calc(9 / 16 * 100%);
  }

}
