// Global styles

@mixin base-font-family {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-family: var(--body-font);
  font-size: 1rem;
  line-height: 1.575rem;
  background-color: var(--background);
  color: var(--color);
  hyphens: auto;
  hyphenate-limit-chars: auto 4;
  overflow-wrap: break-word;
  @media screen and (min-width: 992px) {
    font-size: 1.25rem;
  }
}

.prevent-scroll {
  overflow: hidden;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

// Icons
.icon {
  width: 24px;
  height: 24px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.icon-menu {
  background-image: url('/assets/icons/menu.svg');
}
.icon-menu-dark {
  background-image: url('/assets/icons/menu-dark.svg');
}
.icon-search {
  background-image: url('/assets/icons/search.svg');
}
.icon-search-dark {
  background-image: url('/assets/icons/search-dark.svg');
}
.icon-facebook {
  background-image: url('/assets/icons/facebook.svg');
}
.icon-twitter {
  background-image: url('/assets/icons/twitter.svg');
}
.icon-linkedin {
  background-image: url('/assets/icons/linkedin.svg');
}
.icon-instagram {
  background-image: url('/assets/icons/instagram.svg');
}
.icon-facebook-round {
  background-image: url('/assets/icons/facebook-round.svg');
}
.icon-twitter-round {
  background-image: url('/assets/icons/twitter-round.svg');
}
.icon-linkedin-round {
  background-image: url('/assets/icons/linkedin-round.svg');
}
.icon-copy {
  background-image: url('/assets/icons/copy-link.svg');
}
.icon-copy-round {
  background-image: url('/assets/icons/copy-link-round.svg');
}
.icon-times {
  background-image: url('/assets/icons/times.svg');
}
.icon-times-dark {
  background-image: url('/assets/icons/times-dark.svg');
}
.icon-arrow-right {
  background-image: url('/assets/icons/arrow-right.svg');
}
.icon-arrow-left {
  background-image: url('/assets/icons/arrow-left.svg');
}
.icon-arrow-right-white {
  background-image: url('/assets/icons/arrow-right-white.svg');
}
.icon-arrow-left-white {
  background-image: url('/assets/icons/arrow-left-white.svg');
}
.icon-chevron-right {
  background-image: url('/assets/icons/chevron-right.svg');
}
.icon-chevron-left {
  background-image: url('/assets/icons/chevron-left.svg');
}
.icon-play {
  background-image: url('/assets/icons/play.svg');
}
.icon-pause {
  background-image: url('/assets/icons/pause.svg');
}
.icon-quote {
  background-color: var(--color-paragraph-anchor);
  mask: url('/assets/icons/quote.svg') bottom no-repeat;
  mask-size: contain;
}
.icon-external-link {
  background-image: url('/assets/icons/external-link.svg');
}
.icon-external-link-white {
  background-image: url('/assets/icons/external-link-white.svg');
}
.icon-person {
  background-image: url('/assets/icons/person.svg');
}
.icon-person-dark {
  background-image: url('/assets/icons/person-dark.svg');
}
.icon-live {
  background-image: url('/assets/icons/live.svg');
}
.icon-live-dark {
  background-image: url('/assets/icons/live-dark.svg');
}
.icon-close {
  background-image: url('/assets/icons/close.svg');
}
.icon-settings {
  background-image: url('/assets/icons/settings.svg');
}
.icon-settings-white {
  background-image: url('/assets/icons/settings-white.svg');
}
.icon-bell {
  background-image: url('/assets/icons/bell.svg');
}
.icon-bell-on {
  background-image: url('/assets/icons/bell-on-white.svg');
}
.icon-bell-off {
  background-image: url('/assets/icons/bell-off-white.svg');
}
.icon-speaker {
  background-image: url('/assets/icons/speaker.svg');
}

.btn-icon {
  @extend .border-radius-small;
  padding: 8px;
}

// Font Sizes

.font-size-small {
  font-size: 0.85rem;
  line-height: 1.375rem;
}

.font-size-default {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-size-medium {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.font-size-large {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.font-size-xlarge {
  font-size: 2rem;
  line-height: 2.25rem;
}

h1 {
  @extend .font-size-xlarge;
  margin-bottom: $space-xs;
  color: var(--title-color);
}

h2,
h3 {
  color: var(--title-color);
  margin-bottom: 12px;
}

h3 {
  margin-bottom: 8px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.breadcrumb {
  font-style: normal;
}

p {
  margin-bottom: $space-m;
}

@mixin input-reset {
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 21px;

  &:active,
  &:focus {
    border: none;
    outline: 0;
  }
}

@mixin tooltip-dropdown {
  @extend .box-shadow-large;
  @extend .border-radius;

  display: none;
  position: absolute;
  background-color: $white;
  padding: $space-s;
  z-index: 2000;

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: $white;
    top: -5px;
    right: 17px;
    transform: rotate(45deg);
  }
}

// Helper classes

.btn-red {
  @include button-variant($red, $red);
  @extend .border-radius;

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $red;
    border-color: $red;
  }
}

.btn-primary {
  @include button-variant($color-primary, $color-primary);
  @extend .border-radius;

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.btn-default {
  @include button-variant($white, $light-blue-gray);
  @extend .border-radius;

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $white;
    border-color: $light-blue-gray;
  }
}

.btn-gray {
  @include button-variant($gray-light, $gray-light);
  @extend .border-radius-small;

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: $gray-light;
    border-color: $gray-light;
  }
}

.btn {
  outline: 0;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.btn-clear {
  @extend .border-radius-small;

  background-color: transparent;
  border: none !important;

  &:active,
  &:focus {
    border: none !important;
    outline: 0 !important;
  }
}

.text-highlight {
  background-color: $red;
  color: $white !important;
  padding: 1px 6px 2px 4px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
}

.badge {
  padding: 6px 12px;
}

.badge-light {
  color: $black;
  background-color: $lightest-gray-flat;

  &:hover {
    background-color: $light-gray-flat;
  }
}

// Loading Spinners

.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 50%;
  border: 2px solid $white;
  border-color: $white transparent $white transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @example
<div className="lds-dual-ring" />
*/

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid $white;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* @example
<div className="lds-ripple">
  <div />
  <div />
</div>
*/

.box-shadow-large {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
}

.box-shadow-small {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
}

.border-radius {
  border-radius: 0;
}

.border-radius-small {
  border-radius: 4px;
}

.clearfix:after {
  display: table;
  content: '';
  clear: both;
}

.position-absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.tvGuide {
  .react-tabs {
    &__tab {
      &:not(.react-tabs__tab--selected) {
        background: var(--color-primary) !important;
      }
      &--selected {
        color: var(--white) !important;
        background: var(--red-alt) !important;
        border-color: var(--red-alt) !important;
      }
    }

    &__tab-list {
      position: relative;
      border-bottom: none;
    }
  }

}
