@import 'DivisionPicker.scss';
@import 'GameSlider.scss';
@import 'GameTicker.scss';
@import 'LiveBox.scss';
@import 'LiveGames.scss';
@import 'GameFullpage.scss';
@import 'PlayerFullpage.scss';
@import 'TeamFullpage.scss';
@import 'TeamIcons.scss';

.hockey-data-component {
  position: relative;
  margin-bottom: $space-m;

  button:focus {
    outline: 5px auto $color-primary;
  }

  .-hd-los {
    @include base-font-family;
  }

  &:not(.hockey-data-component-gameslider):not(.hockey-data-component-livegames) > div:not(.branded-title-container) {
    @extend .box-shadow-small;
    @extend .border-radius;

    overflow: hidden;
    background-color: $white;
    color: var(--color-primary);
  }

  .-hd-los-live-games h3,
  .-hd-util-intellitable-scroll-help {
    padding: 8px $space-s;
    margin-bottom: 2px;
  }

  .-hd-util-intellitable {
    border: none;

    .-hd-util-intellitable-data {
      table {
        border: none !important;
        color: var(--color-primary);

        th {
          background-color: $gray-darker;
          color: $white;
          padding: 8px 4px;
        }

        td {
          padding: 8px 4px;
        }

        tr,
        td {
          border: none !important;
        }

        tr:nth-child(odd) td {
          background-color: $white;
        }

        tr:nth-child(even) td {
          background-color: $lightest-gray-flat;
        }

        tr th:first-child,
        tr td:first-child {
          padding-left: $space-s !important;
        }

        tr th:last-child,
        tr td:last-child {
          padding-right: $space-s !important;
        }
      }
    }
  }

  &.hockey-data-component-game-fullreport {
    .-hd-los-game-full-report-headline {
      margin-top: $space-s;
    }

    .-hd-rd-tablet {
      padding: $space-s;
    }
  }

  .-hd-util-tabs,
  .-hd-util-tabs .-hd-util-tabs-pane {
    .-hd-util-tabs-buttons {
      border-bottom: none;
      overflow: auto;
      padding: 0;
      margin: 0 12px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .-hd-util-tabs-button {
      border-radius: 4px 4px 0 0;
      color: $gray-dark;
      font-weight: 500;
      line-height: 32px;
      height: 34px;
    }

    .-hd-util-tabs-button-active,
    .-hd-util-tabs-button:hover {
      line-height: 32px;
      border: 1px solid $lighter-gray-flat;
      border-bottom: none;

      &:last-child {
        border-right: 1px solid $lighter-gray-flat;
      }
    }

    .-hd-util-tabs-content {
      padding: 0 $space-s $space-s !important;
    }
  }
}

.-hd-util-select.-hd-util-select-open .-hd-util-select-list {
  @extend .box-shadow-small;
  @extend .border-radius-small;
}
