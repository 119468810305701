.teaser-anchor {
  &:hover {
    text-decoration: none;
  }
}

.teaser {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  &.teaser-small,
  &.teaser-medium,
  &.teaser-large,
  &.teaser-xlarge {
    @extend .box-shadow-small;
    @extend .border-radius;

    margin-bottom: $space-s;
    overflow: hidden;

    .text-section {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 12px 16px 13px;
    }

    .teaser-title,
    .meta-data {
      text-shadow: 0 0 2px rgba(0, 0, 0, 0.65);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: $space-m;
    }
  }

  .teaser-image {
    width: 100%;
    margin-bottom: 0;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.65) 0%,
        rgba(0, 0, 0, 0.15) 65%,
        rgba(0, 0, 0, 0) 100%
      );
      top: 0;
    }
  }

  .video-duration {
    @extend .border-radius-small;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
    position: absolute;
    top: $space-s;
    right: $space-s;
    bottom: auto;
    padding: 8px 10px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 1px;

    .icon {
      width: 10px;
      height: 10px;
      display: inline-block;
      margin-right: 6px;
    }
  }

  .live-tag {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1000;
  }

  .meta-data {
    font-weight: 600;
    color: $lightest-gray-flat;
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: 4px;
    display: block;
  }

  .teaser-title {
    font-weight: 600;
    color: $white;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: -2px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .teaser-excerpt {
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: $lightest-gray-flat;
    margin-top: 4px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.teaser-xsmall {
    margin-bottom: $space-s;
    flex-direction: row;
    height: 97px;

    .video-duration {
      display: block;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding: 6px 7px;

      .icon {
        margin-right: 0;
      }
    }

    .teaser-image {
      flex: 0 0 60px;
      height: 60px;

      &:after {
        display: none;
      }

      .live-tag {
        display: none;
      }
    }

    .text-section {
      padding: $space-xxxs 0 $space-xxxs $space-s * 1.5;
      position: relative;
      overflow: hidden;
      flex: auto;
    }

    &.no-image {
      .text-section {
        padding: $space-xxxs 0 $space-xxxs 0;
      }

      .meta-data {
        left: 0;
      }
    }

    .teaser-title {
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: var(--title-color);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .meta-data {
      position: absolute;
      bottom: $space-xxxs;
      left: $space-s * 1.5;
      margin-bottom: 0;
      color: darken($light-gray-flat, 23.5%);
    }
  }

  &.no-image {
    .teaser-image {
      background-color: $gray-dark;
      padding-bottom: 56.25%;
    }
  }
}

@mixin image-gradient-small {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0.15) 35%,
    rgba(0, 0, 0, 0) 100%
  );
}

.col-lg-12 {
  .teaser-large {
    .teaser-image:after {
      @include image-gradient-small;
    }
  }
}

@include media-breakpoint-down(lg) {
  .teaser-large {
    .teaser-image:after {
      @include image-gradient-small;
    }
  }
}

@include media-breakpoint-down(sm) {
  .col-xs-12 .teaser-medium {
    .teaser-image:after {
      @include image-gradient-small;
    }
  }
}

// Alternative size and breakpoints

@mixin teaser-tall {
  .imageComponentWrapper {
    height: 0 !important;
    padding-bottom: 75% !important;
    overflow: hidden;
    width: 100% !important;
    position: relative;

    img {
      height: 100%;
      width: 133.3333%;
      left: -16.6667%;
      position: absolute;
      max-width: none;
    }
  }

  &.no-image {
    .teaser-image {
      padding-bottom: 75%;
    }
  }
}

@include media-breakpoint-down(md) {
  .col-md-4,
  .teaser-slider.slides-per-page-3 {
    .teaser {
      @include teaser-tall;
    }
  }
}

@include media-breakpoint-between(smobile, sm) {
  .col-xs-6:not(.col-sm-12),
  .col-sm-6,
  .teaser-slider.slides-per-page-2 {
    .teaser {
      @include teaser-tall;
    }
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-8 {
    align-items: stretch;

    .single-teaser {
      height: 100%;

      .teaser {
        height: calc(100% - 24px);
        position: relative;

        & .teaser-image {
          height: 100%;
        }
      }

      &.has-title {
        .teaser {
          height: calc(100% - 78px);
        }
      }
    }
  }
}
