.teaser-carousel {
  .teaser-list-title {
    //margin-bottom: 0;

    .page-indicator {
      @extend .border-radius-small;

      position: absolute;
      top: auto;
      right: $space-s;
      bottom: $space-m;
      display: block;
      z-index: 1200;
      color: $white;
      background: rgba(0, 0, 0, 0.5);
      padding: 8px 10px;
      font-size: 0.75rem;
      line-height: 0.75rem;
    }

    .icon {
      @extend .border-radius-small;

      position: absolute;
      left: $space-s;
      top: 50%;
      margin-top: -$space-m;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1200;
      padding: $space-m;
      background-size: initial;
    }

    .icon-arrow-left {
      background-image: url(/assets/icons/arrow-left-white.svg);
    }
    .icon-arrow-right {
      left: auto;
      right: $space-s;
      background-image: url(/assets/icons/arrow-right-white.svg);
    }
  }

  &.has-title {
    .teaser-list-title {
      .icon {
        margin-top: -4px;
      }
    }
  }

  &.no-title {
    .teaser-list-title {
      height: 0;
    }
  }

  .teaser {
    .text-section {
      padding-right: $space-l;
    }
  }

  .teaser-collection {
    .teaser-anchor {
      display: block;
      padding: 0 6px;
    }

    @include media-breakpoint-up(md) {
      .teaser-anchor {
        padding: 0 12px;
      }
    }
  }
}
