.cleverpush-tag-button, .cleverpush-topic-button {
  @extend .btn-clear;

  color: var(--white);
  margin-right: 1rem;
  font-size: .8rem;
  display: none;

  &:before {
    @extend .icon;
    @extend .icon-bell-off;

    width: 12px;
    height: 12px;
    content: '';
    margin-right: 6px;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
  }

  &.cleverpush-tag-button,
  &.cleverpush-topic-button-subscribed,
  &.cleverpush-topic-button-unsubscribed {
    display: flex;
    align-items: center;
  }


  &.cleverpush-tag-button-subscribed,
  &.cleverpush-topic-button-subscribed {
    &:before {
      @extend .icon-bell-on;
    }
  }
}

.cleverpush-widget-banner {
  @extend .box-shadow-small;
  @extend .border-radius;

  background-color: $white;
  padding: $space-s;
  margin-bottom: $space-m;
  text-align: center;
  display: flex;
  flex-direction: column;

  .cleverpush-widget-banner-content {
    color: $gray-dark;
    font-weight: 600;
    margin-bottom: $space-s;
  }

  .cleverpush-topic-button {
    display: block;
    border: 2px solid $gray-lighter !important;
    padding: $space-xxs;
    visibility: hidden;

    &:before {
      display: inline-block;
      position: relative;
      top: 0;
      right: $space-xs;
      left: auto;
      margin-bottom: -6px;
    }
  }

  .cleverpush-topic-button-subscribed,
  .cleverpush-topic-button-unsubscribed {
    visibility: visible;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .cleverpush-widget-banner-content {
      margin-left: $space-s;
      margin-bottom: 0;
    }

    .cleverpush-topic-button {
      padding: $space-xxs $space-s $space-xxs 20px;
    }
  }
}
