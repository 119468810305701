.oasis-player {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;

  .video-frame {
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    @extend .border-radius;

    &:after {
      @extend .position-absolute-center;
      content: url('/assets/icons/play.svg');
      height: 40px;
      width: 34px;
      display: block;
      opacity: 0.8;
    }
  }

  .video-frame:not(:empty) {
    &:after {
      display: none;
    }
  }

  &.content-type-live {
    .image-frame {
      &:before {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

#video-wrapper {
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;

  video._oasis_video,
    // toolbar top
  div.css-db09ie,
    // toolbar bottom
  div.css-7n3ess,
    // button container
  div.css-c027s4 {
    @extend .border-radius;
  }

  // Content owner logo
  a > img {
    width: auto;
  }
}

// sticky position
.sticky-player-container {
  @extend .border-radius;

  position: fixed;
  bottom: $space-s;
  right: $space-s;
  z-index: 2000;

  @include media-breakpoint-up(smobile) {
    bottom: $space-m;
    right: $space-m;
  }

  &.collapsed {
    .open-button {
      display: block;
    }

    .stream-wrapper {
      transform: scale(0.1);
      opacity: 0;
      margin-right: -120px;
      margin-bottom: -45px;
      visibility: hidden;
    }
  }

  .stream-wrapper {
    @extend .border-radius;
    @extend .box-shadow-large;
    transition: all 0.15s ease-in-out;
    position: relative;
    max-width: calc(100vw - 30px);
    width: 320px;
    height: 180px;

    @include media-breakpoint-up(xl) {
      width: 400px;
      height: 225px;
    }
  }

  // close X
  div.css-4g6ai3 {
    &,
    & + div {
      display: block !important;
    }
  }
}

.open-button {
  display: none;
  position: absolute;
  width: $space-xl;
  height: $space-xl;
  right: 0;
  bottom: 0;

  @extend .box-shadow-large;
  border-radius: $space-xl;

  span {
    @extend .position-absolute-center;
    font-size: 1.25rem;
    font-weight: 700;
    font-style: italic;
    margin-top: -1px;
    margin-left: -1px;
  }
}
