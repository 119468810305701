.main {
  width: 100vw;
  max-width: 100%;
  padding-top: 6px;
  position: relative;

  section {
    .region {
      margin-bottom: $space-s;
    }
  }

  &.layout-channel,
  &.layout-media {
    .section:first-child {
      .section-title,
      .region-index-0 > *:first-child {
        .component-title,
        .section-title {
          color: var(--title-color);

          a {
            color: var(--title-color);
          }
        }
      }

      @include media-breakpoint-up(lg) {
        .region-index-0:not(.col-lg-12) + .region.col-lg-3,
        .region-index-0:not(.col-lg-12) + .region.col-lg-4,
        .region-index-0:not(.col-lg-12) + .region.col-lg-6,
        .region-index-0:not(.col-lg-12) + .region.col-lg-8 {
          .component-title {
            color: var(--title-color);

            a {
              color: var(--title-color);
            }
          }
        }
      }
    }
  }

  &.layout-channel {
    .section {
      .region-index-0 {
        .teaser-slider,
        .single-teaser,
        .teaser-carousel-wrapper,
        .teaser-grid > div:first-child {
          @extend .gray-overlay;

          &:before {
            top: 80% !important;
          }
        }
      }
    }
  }

  &.layout-media {
    .main-content {
      .section:first-child {
        .region-index-0 {
          @include media-breakpoint-up(lg) {
            flex: 1 0 auto;
            max-width: 100%;
          }
          @include media-breakpoint-up(xl) {
            width: calc(100% - 364px);
          }
        }

        .region-index-1 {
          @include media-breakpoint-up(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
          }
          @include media-breakpoint-up(xl) {
            flex: 0 0 364px;
            min-width: 364px;
          }
        }
      }
    }
  }

  &.layout-plain {
    padding-top: $space-s;

    .article {
      .article-background {
        margin-top: 0;
      }
      header {
        &:before {
          top: 100px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      padding-top: $space-m;
    }
  }
}

.main-content {
  @include media-breakpoint-up(sm) {
    min-height: 600px;
  }
}

.container,
.container-fluid {
  position: relative;
  max-width: 1214px + 300px;
  padding-left: $space-s;
  padding-right: $space-s;
  margin-left: 0;

  @include media-breakpoint-up(sm) {
    padding-left: $space-m;
    padding-right: 160px + $space-m * 2;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $space-l;
  }

  @include media-breakpoint-up(xxl) {
    padding-left: $space-xxl;
    padding-right: 300px + $space-m * 2;
  }
}

// pseudo element for reducing purple background
.gray-overlay {
}

.fullbanner-wrapper {
  &.layout-plain > .container > div {
    margin-top: $space-m;
  }
}

.search-highlight {
  font-weight: 700;
  border-bottom: 3px solid var(--red);
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 15px;
  border:4px solid var(--red-alt);
  text-align:center;
  font-size:1.2em;
  z-index:999;
}
