.weatherContainer {
  position: relative;

  .weather-image {
    &.teaser {
      &.teaser-large {
        .text-section {
          //top: 0;
          //bottom: auto;
          //padding: 25px 30px;
        }
      }
    }
  }
}
