.story-slider {
  position: relative;

  .teaser-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .page-indicator {
      margin-left: $space-xs;
      color: darken($gray-light, 16%);
      font-size: 14px;
      position: relative;
      top: -1px;
    }

    @include media-breakpoint-down(md) {
      margin-top: $space-s;
    }
  }

  .slick-slide {
    & > div {
      display: flex;
      justify-content: center;
    }
  }

  .teaser-collection {
    position: relative;

    .slick-slider {
      margin-left: -12px;
      margin-right: -12px;
    }

    .slick-list {
      padding-top: 2rem;
      background: linear-gradient(to bottom, var(--background-offset) 0%, transparent 50%);
      overflow: hidden;
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-track {
      display: flex;
    }

    a:hover {
      text-decoration: none;
    }

    .teaser-list-slide {
      padding: 0 $space-s;
      width: 180px !important;
    }

    .teaser {
      width: 100%;
      display: flex;
      margin-bottom: 0;
    }

    .text-section {
      flex: 0 0 auto;
      white-space: initial;

      .teaser-excerpt {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(md) {
      .teaser-anchor {
        padding: 0 12px;
      }
    }

    @include media-breakpoint-up(lg) {
      .text-section .teaser-excerpt {
        display: inherit;
      }
    }
  }
}

.controlPrev,
.controlNext {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: calc(50% - 1rem);
  transform: translateY(-50%);
  z-index: 1;

  svg {
    fill: var(--title-color);
    width: .625rem;
    height: 1.625rem;
  }

  &:disabled {
    opacity: 50%;
  }
}

.controlPrev {
  left: -1.375rem;
}

.controlNext {
  right: -1.375rem;
}

// TODO replace to StoryOverlay.scss.
.storyControl {
  position: relative;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  &:before {
    display: flex;
    align-items: center;
    color: rgba(255,255,255,.5);
    font-size: 2.5rem;
    font-weight: bold;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.storyControl--right {
  background: linear-gradient(to right, transparent, rgba(0,0,0,.4)) !important;
  &:before {
    content: '>';
    padding-right: 5px;
    justify-content: flex-end;
  }
}

.storyControl--left {
  background: linear-gradient(to left, transparent, rgba(0,0,0,.4)) !important;
  &:before {
    content: '<';
    padding-left: 5px;
    justify-content: flex-start;
  }
}

.storyControl--disabled {
  z-index: -1 !important;
}
