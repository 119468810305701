.branded-title-container {
  &.no-title {
    height: 30px;
    margin-bottom: 12px;
  }
  &.has-branding {
    h3 {
      padding-right: 214px;

      @media screen and (max-width: 35rem) {
        margin-bottom: 0;
      }
    }
  }
}
